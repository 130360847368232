<script setup lang="ts">
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    height?: string
    width?: string
    class?: string
    src?: string
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const defaultSettingsStore = useDefaultSettingsStore()

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>
<template>
    <img
        :src="defaultSettingsStore?.settings?.contacts?.logo_path"
        class="tw-h-12 tw-w-12"
        :class="props.class"
        :width="width"
        :height="height"
        v-if="defaultSettingsStore?.settings?.contacts?.logo_path !== ''"
    />
    <img
        :src="src ?? '/img/logo.svg'"
        class="tw-h-12 tw-w-36"
        :class="props.class"
        :width="width"
        :height="height"
        v-else
    />
</template>
